.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width:100%;
}

.dark {
  background-color: #242424;
  color: white;
}

.hero {
  height: 100vh;
  max-width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100vh;
}

.title  {
    font-family: AlexBrush-Regular;
    /* font-size: calc(5vw + 5vh); */
    font-size: 25vh;
    padding: 0;
    margin: 0;
    animation: 1s ease-out 0s 1 slideIn;
    text-align: center;
}

.subtitle {
    animation: 3s ease-out 0s 1 slideInSub;
    /* font-size: calc(1vw + 1vh); */
    font-size: 25px;
    text-align: center;
}

.scrollImg {
  animation: 4s ease-out 0s 1 fadeIn;
  width: 2.5vw;
  height: auto;
  cursor: pointer;
  margin-top: 10vh;
}

.scrollImgDark {
  animation: 4s ease-out 0s 1 fadeIn;
  filter: invert(100%);
  width: 2.5vw;
  height: auto;
  cursor: pointer;
  margin-top: 10vh;
}

.row  {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width:100%;
  min-height: 100vh;
  margin-bottom: 25vh;
}

.cols {
  display: flex;
  flex-direction: row;
  flex: 9;
}

.left {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.center {
  display: flex;
  flex-direction: column;
  flex: 5;
  align-items: center;
  justify-content: center;
}

.right {
  display: flex;
  flex-direction: column;
  flex: 2;
}

@media screen and (max-width: 1024px) {
  .scrollImg, .scrollImgDark{
    width: 50px;
  }  
  .title {
    font-size: 150px;
  }
  .subtitle{
    font-size: 20px;
  }
  .row{
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px){
  .title {
    font-size: 100px;
  }
  .subtitle{
    font-size: 15px;
  }
}

@font-face {
  font-family: "AlexBrush-Regular";
  src: url(../assets/fonts/AlexBrush-Regular.ttf);
}
@keyframes slideIn{
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
      opacity: 0;
    }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInSub{
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50%{
      opacity: 0;
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes fadeIn {
0% {
  opacity: 0;
}
50% {
  opacity: 0;
}
100% {
  opacity: 100;
}
}

