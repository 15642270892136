.anchorHeader{
    position:relative;
    height: 100vh;
}

.visibleSm { 
    animation: 0.5s ease-out 0s 1 slideInSideSub;
    display: block !important;
}

.sm {
    position: absolute;
    opacity: 0.5;
    font-size: 5vh;
    margin-left: 5px;
    display: none;
}

.visibleLg{
    animation: 0.5s ease-out 0s 1 slideInSide;
    display: block !important; 
}

.lg {
    position: absolute;
    font-size: 15vh;
    font-weight: bold;
    top: 0;
    margin-left: 50px;
    display: none;
    opacity: 1;
}

@media only screen and (max-width: 1500px) {
  .lg {
    top: -15vh;
  }
  .sm{
    top: -20vh;
  }
}
@media only screen and (max-width: 768px) {
  .anchorHeader{
    display: none;
  }
}

@keyframes slideInSide{
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    50% {
        opacity: 0;
      }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideInSideSub{
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    50%{
        opacity: 0;
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}