.toggler {
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

:root {
    --header-height: 64px;
    scroll-padding-top: var(--header-height);
    scroll-behavior: smooth;
}

a{
    text-decoration: none;
}