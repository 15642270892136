.contact {
    padding-top: 10vh;
}

.centerHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 798px) {
    .centerHeader {
      padding-top: 20vh;
    }
  }

.socials {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.socialIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
}

.socialIcon:hover{
    cursor: pointer;
    animation: slideUp 0.5s ease-out 0s forwards;
}

.socialIcon > *{
    padding-right: 10px;
}

.socialIcon > h2.light {
    color: #1a4d4c;
}

.socialIcon > h2.dark {
    color: #379997;
}

.contactForm{
    width: 400px;
    max-width: 100vw;
    display: flex;
    /* align-items: flex-start; */
    flex-direction: column;
    width: 100%;
    font-size: 16px;
}

@media screen and (max-width: 768px){
    .centerHeader {
        justify-content: center;
        align-items: center;
        padding: 0
    }
    .socials {
        flex-direction: column;
        justify-content: center;
    }
    .contactForm{
        max-width: 100%;
        margin-bottom: 5vh;
    }
    .centerHeader{
        max-width: 100%;
    }
}

.form-control{
    width: 100%;
    height: 35px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
    padding: 7px 0 7px 0;
    margin-top: 5px;
    margin-bottom: 10px;
}

.form-control:focus{
    border: 2px solid #1a4d4c !important;
}

.contactForm.dark > .form-control:focus {
    border: 2px solid #379997 !important;
}

.txtArea {
    padding: 0;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
    resize: none;
    padding: 7px 0 7px 0;
}

.contactForm.dark > .txtArea {
    border: 1px solid rgb(30, 30, 30);
}

.txtArea:focus {
    border: 2px solid #1a4d4c;
}

.contactForm.dark > .txtArea:focus {
    border: 2px solid #379997;
}

.form-label {
    margin-bottom: 1rem !important;
  }

.sendButton{
    width: 100%;
    height: 35px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
    padding: 7px 0 7px 0;
    margin-top: 2rem;
    cursor: pointer;
    background: #1a4d4c;
    color: white;
    border: none;
}

.sendButton.dark{
    background: #379997;
}

.contactForm.dark > input[type="submit"]{
    background: #379997;
}

/*Spinner */
.lds-dual-ring {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 15px;
    height: 15px;
    margin: 8px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

