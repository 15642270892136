.work {
    display: flex;
    flex-direction: row;
    width: 100vw;
    flex-wrap: wrap;
    padding-top: 20vh;
}

a, a:hover, a:active, a:focus {
    text-decoration: none;
    color: inherit;
}

.card.dark {
    border: 1px solid white;
}

.card.dark:hover{
    animation: slideUp 0.5s ease-out 0s forwards;
}

.card.dark:hover > .cardTitle {
    color: #379997
}

.card {
    height: 400px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    flex: 1 0 calc(25% - 10px);
    margin: 5px;
    width: 300px;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.card:hover{
    animation: slideUp 0.5s ease-out 0s forwards;
}

.card:hover > .cardTitle {
    color: #1a4d4c;
}



@keyframes slideUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-5px);
    }
}

.cardHeader {
    max-height: 60px;
    display: flex;
    align-items: center;
    flex: 6;
    margin-bottom: 5px;
}

.icon {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

a.icon > .githubIcon:hover {
    filter: invert(21%) sepia(83%) saturate(337%) hue-rotate(130deg) brightness(96%) contrast(92%);
}

a.icon.dark > .githubIcon:hover {
    filter: invert(76%) sepia(37%) saturate(549%) hue-rotate(130deg) brightness(200%) contrast(104%);
} 

.spacer {
    flex: 4;
}

.cardTitle{
    margin-bottom: 10px;
    font-weight: bold;
}

.tech {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: auto;
    margin-bottom: 5px;
}

.techItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
}