.anchorHeaderMobile {
    display: none;
}

@media screen and (max-width: 768px){
    .anchorHeaderMobile {
        display: flex;
    }
    .smMobile {
        opacity: 0.5;
        font-size: 40px;
        display: flex;
    }
    .lgMobile {
        font-size: 80px;
        font-weight: bold;
        display: flex;
        opacity: 1;
    }
}