* {
    scroll-behavior: smooth;
  }

  #root {
    max-width: 100%;
  }

  .App{
    max-width:100%;
  }