.about {
  /* padding: 20vh 10% 0 10%; */
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding-top: 10vh;
  /* flex-wrap: wrap; */
}

.scroll2Img {
  animation: 4s ease-out 0s 1 fadeIn;
  filter: invert(100%);
  width: 10vw;
  height: auto;
  cursor: pointer;
  /* margin-top: 10vh; */
}

.scroll2ImgDark {
  animation: 4s ease-out 0s 1 fadeIn;
  width: 10vw;
  height: auto;
  cursor: pointer;
  /* margin-top: 10vh; */
}

.avatar {
  min-height: 200px;
  min-width: 200px;
  vertical-align: middle;
  border-radius: 50%;
  object-fit: cover;
  width: 50vh;
  aspect-ratio: 1/1;
}

.subcontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .avatar {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 780px) {
  .subcontainer {
    flex-direction: column;
  }
  .avatar {
    max-width: 5vw;
  }
}
